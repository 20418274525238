import { useQuery } from "@tanstack/react-query";
import { client } from "@/queries/client";
import { components } from "@/lib/openapi";

export type PricedFlight = components["schemas"]["PricedFlightSchema"];
export type FlightFeeFilter = components["schemas"]["FlightFeeFilter"];
export type SingleDayAggregateFees =
  components["schemas"]["SingleDayAggregateFees"];
export type MultiDayAggregateFees =
  components["schemas"]["MultiDayAggregateFees"];

interface QueryOptions {
  enabled?: boolean;
}

// Get all available dates with fee data
export function getAvailableDates() {
  return useQuery({
    queryKey: ["landing_dates"],
    queryFn: async ({ signal }) => {
      const { data, error } = await client
        .get()
        .GET("/api/landing/dates", { signal });
      if (data) {
        // Ensure dates are properly formatted in UTC
        return data.map((date: string) => {
          const utcDate = new Date(date);
          return utcDate.toISOString().split("T")[0];
        });
      }
      console.warn(error);
      throw new Error();
    },
  });
}

interface PricedFlightsOptions extends QueryOptions {
  feeDate: string;
  filter: FlightFeeFilter;
}

// Get priced flights for a specific date
export function getPricedFlights({
  feeDate,
  filter,
  enabled = false,
}: PricedFlightsOptions) {
  return useQuery({
    queryKey: ["priced_flights", feeDate, filter],
    queryFn: async ({ signal }) => {
      const { data, error } = await client
        .get()
        .POST("/api/landing/flights/{fee_date}", {
          params: {
            path: {
              fee_date: feeDate,
            },
          },
          body: { filter },
          signal,
        });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
    enabled,
  });
}

interface DailyReportOptions extends QueryOptions {
  date: string;
  filter?: FlightFeeFilter;
}

// Get fee report for a single day
export function getDailyReport({
  date,
  filter,
  enabled = false,
}: DailyReportOptions) {
  return useQuery({
    queryKey: ["daily_report", date, filter],
    queryFn: async ({ signal }) => {
      const { data, error } = await client
        .get()
        .POST("/api/landing/report/day/{date}", {
          params: {
            path: {
              date,
            },
          },
          body: filter,
          signal,
        });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
    enabled,
  });
}

interface RangeReportOptions extends QueryOptions {
  startDate: string;
  endDate: string;
  filter?: FlightFeeFilter;
}

// Get fee report for a date range
export function getRangeReport({
  startDate,
  endDate,
  filter,
  enabled = false,
}: RangeReportOptions) {
  return useQuery({
    queryKey: ["range_report", startDate, endDate, filter],
    queryFn: async ({ signal }) => {
      const { data, error } = await client
        .get()
        .POST("/api/landing/report/range", {
          params: {
            query: {
              start_date: startDate,
              end_date: endDate,
            },
          },
          body: filter,
          signal,
        });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
    enabled,
  });
}
