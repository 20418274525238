import React from "react";
import {
  Plane,
  MapPin,
  Scale,
  AlertTriangle,
  Ban,
  RotateCcw,
} from "lucide-react";
import { PricedFlight } from "@/queries/landing";

const formatMoney = (cents: number) => {
  return `$${(cents / 100).toFixed(2)}`;
};

const formatWeight = (lbs: number | null) => {
  if (!lbs) return "N/A";
  return `${lbs.toLocaleString()} lbs`;
};

const getStatusColor = (flightType: string) => {
  switch (flightType) {
    case "Scheduled":
      return "text-green-400";
    case "GA":
      return "text-blue-400";
    case "Unscheduled":
      return "text-yellow-400";
    default:
      return "text-gray-400";
  }
};

const getFeeTierBadge = (feeTier: string, isSignatory: boolean) => {
  const baseClasses = "text-xs px-2 py-1 rounded-full";
  if (isSignatory) {
    return (
      <span className={`${baseClasses} bg-green-900 text-green-200`}>
        Signatory
      </span>
    );
  }
  switch (feeTier) {
    case "general_aviation":
      return (
        <span className={`${baseClasses} bg-blue-900 text-blue-200`}>
          General Aviation
        </span>
      );
    case "non_signatory":
      return (
        <span className={`${baseClasses} bg-yellow-900 text-yellow-200`}>
          Non-Signatory
        </span>
      );
    default:
      return (
        <span className={`${baseClasses} bg-gray-700 text-gray-200`}>
          {feeTier}
        </span>
      );
  }
};

const getDiversionBadge = (flight: PricedFlight) => {
  if (!flight.diversion) return null;

  const baseClasses = "text-xs px-2 py-1 rounded-full flex items-center gap-1";

  if (flight.diversion_type === "returned") {
    return (
      <span className={`${baseClasses} bg-orange-900 text-orange-200`}>
        <RotateCcw className="h-3 w-3" />
        Returned to Airport
      </span>
    );
  }

  if (flight.diversion_type === "diverted_away") {
    return (
      <span className={`${baseClasses} bg-purple-900 text-purple-200`}>
        <AlertTriangle className="h-3 w-3" />
        Diverted to {flight.diversion_airport_code}
      </span>
    );
  }

  return null;
};

const getIgnoredBadge = (flight: PricedFlight) => {
  if (!flight.ignored) return null;

  return (
    <span className="text-xs px-2 py-1 rounded-full bg-red-900 text-red-200 flex items-center gap-1">
      <Ban className="h-3 w-3" />
      Ignored{flight.ignored_reason ? `: ${flight.ignored_reason}` : ""}
    </span>
  );
};

const getAircraftDisplay = (flight: PricedFlight) => {
  const parts = [];

  if (flight.aircraft_code) {
    parts.push(flight.aircraft_code);
  }

  if (flight.aircraft_name) {
    parts.push(flight.aircraft_name);
  }

  if (parts.length === 0) {
    parts.push(flight.aircraft_name || "Unknown Aircraft");
  }

  return parts.join(" - ");
};

const getRouteDisplay = (flight: PricedFlight) => {
  if (flight.diversion) {
    if (flight.diversion_type === "returned") {
      return (
        <div className="flex items-center gap-2">
          <span className="font-mono">{flight.departure_airport_code}</span>
          <span className="text-orange-400">↺</span>
          <span className="font-mono">{flight.arrival_airport_code}</span>
          <span className="text-gray-500 text-xs">
            (Originally to {flight.original_arrival_airport_code})
          </span>
        </div>
      );
    }

    if (flight.diversion_type === "diverted_away") {
      return (
        <div className="flex items-center gap-2">
          <span className="font-mono">{flight.departure_airport_code}</span>
          <span className="text-purple-400">→</span>
          <span className="font-mono">{flight.arrival_airport_code}</span>
          <span className="text-gray-500 text-xs">
            (Diverted from {flight.original_arrival_airport_code})
          </span>
        </div>
      );
    }
  }

  return (
    <div className="flex items-center gap-2">
      <span className="font-mono">{flight.departure_airport_code}</span>
      <span className="text-gray-500">→</span>
      <span className="font-mono">{flight.arrival_airport_code}</span>
    </div>
  );
};

interface FlightDetailProps {
  flight: PricedFlight;
}

export function PricedFlightDetail({ flight }: FlightDetailProps) {
  return (
    <div
      className={`bg-gray-800 rounded-lg p-6 hover:bg-gray-750 transition-colors ${
        flight.ignored ? "opacity-75" : ""
      }`}
    >
      <div className="flex flex-col gap-4">
        {/* Header Row */}
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3 flex-wrap">
            <span
              className={`text-lg font-bold ${getStatusColor(flight.flight_type)}`}
            >
              {flight.flight_number_display ||
                flight.tail_number ||
                "GA Flight"}
            </span>
            {getFeeTierBadge(flight.fee_tier, flight.is_signatory)}
            {getDiversionBadge(flight)}
            {getIgnoredBadge(flight)}
          </div>
          <div className="text-xl font-bold text-green-400">
            {formatMoney(flight.landing_fee_cents)}
          </div>
        </div>

        {/* Details Grid */}
        <div className="grid md:grid-cols-2 gap-4">
          {/* Left Column */}
          <div className="space-y-3">
            <div className="flex items-center gap-2">
              <Plane className="h-4 w-4 text-gray-400" />
              <span className="text-sm text-gray-300">Aircraft:</span>
              <span className="font-mono">{getAircraftDisplay(flight)}</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="text-sm text-gray-300">Tail Number:</span>
              <span className="font-mono">{flight.tail_number || "N/A"}</span>
            </div>
            <div className="flex items-center gap-2">
              <MapPin className="h-4 w-4 text-gray-400" />
              {getRouteDisplay(flight)}
            </div>
          </div>

          {/* Right Column */}
          <div className="space-y-3">
            <div className="flex items-center gap-2">
              <Scale className="h-4 w-4 text-gray-400" />
              <span className="text-sm text-gray-300">MTOW:</span>
              <span className="font-mono">{formatWeight(flight.mtow_lbs)}</span>
            </div>
            <div className="flex items-center gap-2">
              <Scale className="h-4 w-4 text-gray-400" />
              <span className="text-sm text-gray-300">MLW:</span>
              <span className="font-mono">{formatWeight(flight.mlw_lbs)}</span>
            </div>
          </div>
        </div>

        {/* Time Row */}
        <div className="flex justify-between pt-2 border-t border-gray-700">
          <div className="flex items-center gap-4">
            <div className="flex flex-col items-center justify-center">
              <span className="text-lg font-mono">
                {flight.departure_time_display}
              </span>
              <span className="text-xs text-gray-400">Departure</span>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex flex-col items-center justify-center">
              <span className="text-lg font-mono">
                {flight.arrival_time_display}
              </span>
              <span className="text-xs text-gray-400">Arrival</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
