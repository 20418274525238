import type React from "react";
import { useState, createContext, useContext } from "react";
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Button } from "@/components/ui/button";
import { Switch } from "@/components/ui/switch";
import type { components } from "@/lib/openapi";

type FlightFeeFilter = components["schemas"]["FlightFeeFilter"];
type FeeTier = components["schemas"]["FeeTier"];

interface FilterContextType {
  filters: FlightFeeFilter;
  setFilters: React.Dispatch<React.SetStateAction<FlightFeeFilter>>;
}

const FilterContext = createContext<FilterContextType | undefined>(undefined);

const useFilterContext = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error("useFilterContext must be used within a FilterProvider");
  }
  return context;
};

export const FilterProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [filters, setFilters] = useState<FlightFeeFilter>({
    carriers: null,
    flight_types: null,
    tiers: null,
    include_diversion: true,
  });

  return (
    <FilterContext.Provider value={{ filters, setFilters }}>
      {children}
    </FilterContext.Provider>
  );
};

interface Option {
  value: string;
  label: string;
}

interface MultiSelectProps {
  label: string;
  options: Option[];
  value: string[] | null;
  onChange: (value: string[] | null) => void;
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  label,
  options,
  value,
  onChange,
}) => {
  const [showList, setShowList] = useState(false);

  const handleChange = (optionValue: string, checked: boolean) => {
    if (value === null) {
      onChange(options.map((o) => o.value).filter((v) => v !== optionValue));
    } else {
      onChange(
        checked
          ? [...value, optionValue]
          : value.filter((v) => v !== optionValue),
      );
    }
  };

  const handleSelectAll = () => {
    onChange(null);
    setShowList(false);
  };

  const allSelected = value === null;

  return (
    <div className="space-y-2">
      <Label className="font-semibold dark:text-slate-400">{label}</Label>
      {allSelected ? (
        <div className="flex items-center space-x-2">
          <span className="text-sm dark:text-slate-500">
            All {label.toLowerCase()}
          </span>
          <button
            className="text-slate-300 text-sm underline"
            onClick={() => setShowList(true)}
          >
            Select
          </button>
        </div>
      ) : (
        <div className="flex items-center space-x-2">
          <span className="text-sm dark:text-slate-500">
            {value?.length || 0} {label.toLowerCase()} selected
          </span>
          {value?.length !== options.length && (
            <button
              className="text-slate-300 text-sm underline"
              onClick={handleSelectAll}
            >
              Select All
            </button>
          )}
        </div>
      )}
      {showList && (
        <ScrollArea className="h-60 w-full border rounded">
          <div className="p-4 space-y-2">
            {options.map(({ value: optionValue, label: optionLabel }) => (
              <div key={optionValue} className="flex items-center space-x-2">
                <Checkbox
                  id={`${label}-${optionValue}`}
                  checked={allSelected || value?.includes(optionValue)}
                  onCheckedChange={(checked) =>
                    handleChange(optionValue, checked as boolean)
                  }
                />
                <Label
                  htmlFor={`${label}-${optionValue}`}
                  className="text-sm text-slate-200"
                >
                  {optionLabel}
                </Label>
              </div>
            ))}
          </div>
        </ScrollArea>
      )}
    </div>
  );
};

const carriers = {
  AA: "American Airlines",
  AC: "Air Canada",
  AF: "Air France",
  BA: "British Airways",
  CX: "Cathay Pacific",
  DL: "Delta Air Lines",
  EK: "Emirates",
  LH: "Lufthansa",
  QF: "Qantas",
  SQ: "Singapore Airlines",
  TK: "Turkish Airlines",
  UA: "United Airlines",
  WS: "WestJet",
  KL: "KLM Royal Dutch Airlines",
  NH: "All Nippon Airways",
};

const CarrierFilter: React.FC = () => {
  const { filters, setFilters } = useFilterContext();

  const handleChange = (value: string[] | null) => {
    setFilters((prev) => ({ ...prev, carriers: value }));
  };

  return (
    <MultiSelect
      label="CARRIERS"
      options={Object.entries(carriers).map(([value, label]) => ({
        value,
        label,
      }))}
      value={filters.carriers}
      onChange={handleChange}
    />
  );
};

const flightTypes = [
  { value: "scheduled", label: "Scheduled" },
  { value: "unscheduled", label: "Unscheduled" },
  { value: "general_aviation", label: "General Aviation" },
];

const FlightTypeFilter: React.FC = () => {
  const { filters, setFilters } = useFilterContext();

  const handleChange = (value: string[] | null) => {
    setFilters((prev) => ({ ...prev, flight_types: value }));
  };

  return (
    <MultiSelect
      label="FLIGHT TYPES"
      options={flightTypes}
      value={filters.flight_types}
      onChange={handleChange}
    />
  );
};

const feeTiers: { value: FeeTier; label: string }[] = [
  { value: "signatory", label: "Signatory" },
  { value: "non_signatory", label: "Non-Signatory" },
  { value: "general_aviation", label: "General Aviation" },
];

const FeeTierFilter: React.FC = () => {
  const { filters, setFilters } = useFilterContext();

  const handleChange = (value: string[] | null) => {
    setFilters((prev) => ({ ...prev, tiers: value as FeeTier[] | null }));
  };

  return (
    <MultiSelect
      label="FEE TIERS"
      options={feeTiers}
      value={filters.tiers}
      onChange={handleChange}
    />
  );
};

const DiversionFilter: React.FC = () => {
  const { filters, setFilters } = useFilterContext();

  const handleDiversionChange = (checked: boolean) => {
    setFilters((prev) => ({
      ...prev,
      include_diversion: checked,
    }));
  };

  return (
    <div className="flex items-center space-x-2">
      <Switch
        id="include-diversion"
        className="opacity-70"
        checked={filters.include_diversion}
        onCheckedChange={handleDiversionChange}
      />
      <Label htmlFor="include-diversion" className="uppercase text-slate-400">
        Include Diversions
      </Label>
    </div>
  );
};

export const FilterBuilder: React.FC = () => {
  const { filters, setFilters } = useFilterContext();
  const [appliedFilters, setAppliedFilters] = useState(filters);

  const handleApply = () => {
    setAppliedFilters(filters);
  };

  return (
    <div className="mt-4 w-full max-w-3xl mx-auto space-y-6">
      <div className="space-y-6">
        <CarrierFilter />
        <FlightTypeFilter />
        <FeeTierFilter />
        <DiversionFilter />
      </div>
      <Button onClick={handleApply} size="sm" variant="secondary">
        Apply Filters
      </Button>
      {/*<div className="mt-6 p-4 bg-gray-800 rounded">*/}
      {/*  <h3 className="text-lg font-semibold mb-2">Applied Filters:</h3>*/}
      {/*  <pre className="whitespace-pre-wrap overflow-x-auto">{JSON.stringify(appliedFilters, null, 2)}</pre>*/}
      {/*</div>*/}
    </div>
  );
};
