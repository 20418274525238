import { PricedFlight } from "@/queries/landing";
import React, { useMemo, useState } from "react";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ClipboardIcon,
} from "@radix-ui/react-icons";
import { clsx } from "clsx";
import _ from "lodash";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { PricedFlightDetail } from "@/components/landing/PricedFlightDetail";

export function PricedFlightTable({ flights }: { flights: PricedFlight[] }) {
  return (
    <div className="flex flex-col space-y-4">
      {flights.map((f) => (
        <PricedFlightDetail flight={f} />
      ))}
    </div>
  );
}

function OldPricedFlightTable({ flights }: { flights: PricedFlight[] }) {
  const [sortKey, setSortKey] = useState<keyof PricedFlight | null>(null);
  const [sortAsc, setSortAsc] = useState(true);
  const [selectedRawData, setSelectedRawData] = useState<string | null>(null);

  const sortedFlights = useMemo(() => {
    if (!sortKey) return flights;
    return _.orderBy(flights, [sortKey], [sortAsc ? "asc" : "desc"]);
  }, [flights, sortKey, sortAsc]);

  const renderSort = (key: keyof PricedFlight) => {
    if (key === sortKey) {
      return sortAsc ? (
        <ChevronUpIcon className="w-4 h-4 opacity-80 mr-2" />
      ) : (
        <ChevronDownIcon className="w-4 h-4 opacity-80 mr-2" />
      );
    }
    return null;
  };

  const setSort = (key: keyof PricedFlight) => {
    if (sortKey === key) {
      setSortAsc(!sortAsc);
    } else {
      setSortKey(key);
      setSortAsc(true);
    }
  };

  function renderTableHeader(
    key: keyof PricedFlight,
    name: string,
    sortable: boolean,
    width: string,
  ) {
    const handleClick = () => {
      if (sortable) {
        setSort(key);
      }
    };

    return (
      <th className={`${width} cursor-pointer text-sm`} onClick={handleClick}>
        <div className="flex items-center justify-center">
          {sortable ? (
            <>
              {renderSort(key)} {name}
            </>
          ) : (
            name
          )}
        </div>
      </th>
    );
  }

  const formatTime = (time: string | null | undefined) => {
    if (!time) return "";
    return new Date(time).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  };

  const formatCurrency = (cents: number) => {
    return `$${(cents / 100).toFixed(2)}`;
  };

  const handleRawDataClick = (rawData: any) => {
    try {
      // If the data is already a string, parse it first
      const parsed =
        typeof rawData === "string" ? JSON.parse(rawData) : rawData;
      // Then stringify it with proper formatting
      setSelectedRawData(JSON.stringify(parsed, null, 2));
    } catch (e) {
      // Fallback to raw string if parsing fails
      setSelectedRawData(String(rawData));
    }
  };

  const handleCopyClick = () => {
    if (selectedRawData) {
      navigator.clipboard.writeText(selectedRawData);
    }
  };

  return (
    <div className="space-y-4">
      <table className="flight-table">
        <thead>
          <tr>
            {renderTableHeader("flight_type", "Type", true, "w-24")}
            {renderTableHeader("flight_number_display", "Flight", true, "w-32")}
            {renderTableHeader("departure_airport_code", "DEP", true, "w-20")}
            {renderTableHeader("arrival_airport_code", "ARR", true, "w-20")}
            {renderTableHeader(
              "departure_time_display",
              "Departure",
              true,
              "w-32",
            )}
            {renderTableHeader("arrival_time_display", "Arrival", true, "w-32")}
            {renderTableHeader("landing_fee_cents", "Fee", true, "w-24")}
            {renderTableHeader("tail_number", "Tail #", true, "w-28")}
            {renderTableHeader("aircraft_name", "Aircraft", true, "w-32")}
            {renderTableHeader("mlw_lbs", "MLW", true, "w-24")}
            {renderTableHeader("fee_tier", "Tier", true, "w-28")}
          </tr>
        </thead>
        <tbody>
          {sortedFlights.map((flight) => (
            <tr
              key={flight.id}
              className={clsx(
                flight.diversion && "!bg-orange-900/30",
                flight.diverted_away && "opacity-50",
                flight.is_signatory && "!bg-yellow-900/30",
                flight.raw_data && "cursor-pointer",
              )}
              onDoubleClick={() => {
                if (flight.raw_data) {
                  handleRawDataClick(flight.raw_data);
                }
              }}
            >
              <td>{flight.flight_type || "-"}</td>
              <td>{flight.flight_number_display || "-"}</td>
              <td>{flight.departure_airport_code || "-"}</td>
              <td>{flight.arrival_airport_code || "-"}</td>
              <td>{flight.departure_time_display}</td>
              <td>{flight.arrival_time_display}</td>
              <td>{formatCurrency(flight.landing_fee_cents)}</td>
              <td>{flight.tail_number || "-"}</td>
              <td>{flight.aircraft_name || "-"}</td>
              <td>{flight.mlw_lbs?.toLocaleString() || "-"}</td>
              <td className="capitalize">
                {flight.fee_tier.replace("_", " ")}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Dialog
        open={!!selectedRawData}
        onOpenChange={() => setSelectedRawData(null)}
      >
        <DialogContent className="max-w-4xl max-h-[80vh]">
          <DialogHeader className="flex flex-row items-center justify-between">
            <DialogTitle>Raw Flight Data</DialogTitle>
            <button
              onClick={handleCopyClick}
              className="flex items-center gap-2 px-3 py-1 text-sm rounded hover:bg-gray-800"
            >
              <ClipboardIcon className="w-4 h-4" />
              Copy JSON
            </button>
          </DialogHeader>
          <div
            className="relative flex-1"
            style={{ height: "calc(80vh - 120px)" }}
          >
            <pre className="absolute inset-0 overflow-auto bg-gray-900 text-gray-100 p-4 rounded-md text-sm">
              {selectedRawData}
            </pre>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
