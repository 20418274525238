import { usePage } from "@/components/page/PageContext";
import React, { useEffect, useMemo, useState } from "react";
import {
  getAvailableDates,
  getDailyReport,
  getPricedFlights,
  PricedFlight,
} from "@/queries/landing";
import { Calendar } from "@/components/ui/calendar";
import { Skeleton } from "@/components/ui/skeleton";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { AlertCircle } from "lucide-react";
import { ChevronUpIcon, ChevronDownIcon } from "@radix-ui/react-icons";
import { clsx } from "clsx";
import _ from "lodash-es";
import { FilterBuilder } from "@/components/landing/FeeFilter";
import { PricedFlightTable } from "@/components/landing/PricedFlightTable";

function AvailableDaysCalendar({
  selectedDate,
  onDateSelected,
}: {
  selectedDate: string;
  onDateSelected: (date: string) => void;
}) {
  const { data: availableDates, isLoading } = getAvailableDates();

  function handleDateSelect(date: Date | undefined) {
    if (date) {
      const formattedDate = date.toISOString().split("T")[0];
      onDateSelected(formattedDate);
    }
  }

  if (isLoading) {
    return <Skeleton className="w-64 h-64" />;
  }

  const latestDate = availableDates
    ? new Date(availableDates[availableDates.length - 1])
    : new Date();

  return (
    <div>
      <Calendar
        mode="single"
        selected={new Date(selectedDate)}
        onSelect={handleDateSelect}
        defaultMonth={latestDate}
        className="rounded-md border"
        disabled={(date: Date) => {
          const dateStr = date.toISOString().split("T")[0];
          return !availableDates?.includes(dateStr);
        }}
      />
    </div>
  );
}

const FEE_TYPE_NAMES = {
  "FeeType.LANDING": "Landing",
  "FeeType.PARKING": "Parking",
} as const;

const FEE_TIER_NAMES = {
  general_aviation: "General Aviation",
  signatory: "Signatory",
  non_signatory: "Non-Signatory",
} as const;

const FLIGHT_TYPE_NAMES = {
  Unscheduled: "Unscheduled",
  GA: "General Aviation",
  Scheduled: "Scheduled",
} as const;

function SingleDayReport({ feeDate }: { feeDate: string }) {
  const { data, isLoading } = getDailyReport({
    date: feeDate,
    enabled: true,
    filter: {
      include_diversion: true,
    },
  });

  const formatCurrency = (cents: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(cents / 100);
  };

  if (isLoading) {
    return <Skeleton className="w-full h-64" />;
  }

  if (!data) {
    return null;
  }

  // Sort carriers by amount and take top 3 instead of 5
  const topCarriers = [...data.carrier_breakdown]
    .sort((a, b) => b.amount_cents - a.amount_cents)
    .slice(0, 3);

  const formattedDate = new Date(feeDate).toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  return (
    <div className="border-gray-600 border bg-slate-900 rounded-lg shadow-lg p-6 w-full text-slate-200">
      <h2 className="text-xl font-semibold mb-4 text-slate-100">
        Landing Fee Report - {formattedDate}
      </h2>

      {/* KPI Section - Updated grid to make cards equal width */}
      <div className="grid grid-cols-3 gap-4 mb-6">
        <div className="bg-slate-700 p-4 rounded-lg">
          <div className="text-sm text-slate-400">Total Revenue</div>
          <div className="text-2xl font-bold text-slate-100">
            {formatCurrency(data.total_fees)}
          </div>
        </div>
        <div className="bg-slate-700 p-4 rounded-lg">
          <div className="text-sm text-slate-400">Total Landings</div>
          <div className="text-2xl font-bold text-slate-100">
            {data.flight_count}
          </div>
        </div>
        <div className="bg-slate-700 p-4 rounded-lg">
          <div className="text-sm text-slate-400">Average Fee</div>
          <div className="text-2xl font-bold text-slate-100">
            {formatCurrency(data.total_fees / data.flight_count)}
          </div>
        </div>
      </div>

      {/* Breakdowns Section */}
      <div className="grid grid-cols-3 gap-6">
        {/* Flight Type Breakdown */}
        <div>
          <h3 className="text-sm font-semibold text-slate-400 mb-2">
            Flight Types
          </h3>
          <div className="space-y-2">
            {data.flight_type_breakdown.map((breakdown) => (
              <div key={breakdown.item} className="flex justify-between">
                <span className="text-sm text-slate-300">
                  {FLIGHT_TYPE_NAMES[breakdown.item] || breakdown.item}
                </span>
                <span className="font-medium text-slate-200">
                  {formatCurrency(breakdown.amount_cents)}
                </span>
              </div>
            ))}
          </div>
        </div>

        {/* Carrier Breakdown */}
        <div>
          <h3 className="text-sm font-semibold text-slate-400 mb-2">
            Top Carriers
          </h3>
          <div className="space-y-2">
            {topCarriers.map((breakdown) => (
              <div key={breakdown.item} className="flex justify-between">
                <span className="text-sm text-slate-300">{breakdown.item}</span>
                <span className="font-medium text-slate-200">
                  {formatCurrency(breakdown.amount_cents)}
                </span>
              </div>
            ))}
          </div>
        </div>

        {/* Fee Tier Breakdown */}
        <div>
          <h3 className="text-sm font-semibold text-slate-400 mb-2">
            Fee Tiers
          </h3>
          <div className="space-y-2">
            {data.fee_tier_breakdown.map((breakdown) => (
              <div key={breakdown.item} className="flex justify-between">
                <span className="text-sm text-slate-300">
                  {FEE_TIER_NAMES[breakdown.item] || breakdown.item}
                </span>
                <span className="font-medium text-slate-200">
                  {formatCurrency(breakdown.amount_cents)}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export function DayViewPage() {
  const navigate = useNavigate();
  const { date } = useParams();
  const { setTitles } = usePage();
  const { data: availableDates, isLoading: datesLoading } = getAvailableDates();

  // Get current date from URL or latest available date
  const currentDate = useMemo(() => {
    if (datesLoading || !availableDates?.length) return "";
    return date && availableDates.includes(date)
      ? date
      : availableDates[availableDates.length - 1];
  }, [date, availableDates, datesLoading]);

  // Update title to include the selected date
  const titles = useMemo(() => {
    const formattedDate = currentDate
      ? new Date(currentDate).toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
          year: "numeric",
        })
      : "";
    return [`Landing Fees`];
  }, [currentDate]);

  useEffect(() => {
    setTitles(titles);
  }, [setTitles, titles]);

  const {
    data: pricedFlightData,
    isLoading: flightsLoading,
    error: flightsError,
  } = getPricedFlights({
    enabled: !!currentDate,
    feeDate: currentDate,
    filter: {
      include_diversion: true,
    },
  });

  if (datesLoading) {
    return <Skeleton className="w-full h-64" />;
  }

  if (!availableDates?.length) {
    return (
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>No available dates found</AlertDescription>
      </Alert>
    );
  }

  const handleDateSelected = (newDate: string) => {
    navigate(`/data/landing/day/${newDate}`);
  };

  return (
    <div>
      <div className="flex w-full p-4">
        <div>
          <div className="uppercase mb-2 text-slate-400">Select Date:</div>
          <AvailableDaysCalendar
            selectedDate={currentDate}
            onDateSelected={handleDateSelected}
          />
          <FilterBuilder />
        </div>
        <div className="flex-grow pl-8">
          {currentDate && <SingleDayReport feeDate={currentDate} />}
          {flightsLoading && <Skeleton className="w-full h-64" />}
          {flightsError && (
            <Alert variant="destructive">
              <AlertCircle className="h-4 w-4" />
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>Failed to load flight data</AlertDescription>
            </Alert>
          )}
          {pricedFlightData && (
            <div className="w-full mt-4">
              <h2 className="text-xl font-semibold mb-4 text-slate-100">
                Landings
              </h2>
              <PricedFlightTable flights={pricedFlightData} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
