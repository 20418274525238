import React, { FunctionComponent, useMemo, useState, useEffect } from "react";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { getTerminals } from "@/queries/terminals";
import { useSessionData } from "@/stores/SessionDataContext";
import { getFlightData, toggleFlightStar } from "@/queries/flights";
import { useReportContext } from "@/stores/ReportContext";
import { components } from "@/lib/openapi";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  ArrowUpIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ExclamationTriangleIcon,
  StarFilledIcon,
  StarIcon,
} from "@radix-ui/react-icons";
import { clsx } from "clsx";
import { Checkbox } from "@/components/ui/checkbox";
import _ from "lodash";
import ScrollToTop from "react-scroll-to-top";
import { usePage } from "@/components/page/PageContext";

type Flight = components["schemas"]["FlightSchema"];
type SortKey = keyof Flight;

const useFilteredSortedFlights = (
  flights: Flight[],
  direction: string,
  terminal: string,
  sortKey: SortKey | null,
  sortAsc: boolean,
  showPast: boolean,
) => {
  return useMemo(() => {
    if (!flights) {
      return [];
    }

    let filtered = flights.filter((flight) => {
      if (terminal !== "0" && flight.terminal !== terminal) return false;

      const now = new Date();
      const currentTime = now.getHours() * 60 + now.getMinutes();
      const flightTime =
        direction === "arrival" ? flight.sch_arr_time : flight.sch_dep_time;
      const [hours, minutes] = flightTime.split(":").map(Number);
      const flightTimeMinutes = hours * 60 + minutes;

      if (!showPast && flightTimeMinutes < currentTime) return false;

      return true;
    });

    if (sortKey) {
      filtered = _.orderBy(filtered, [sortKey], [sortAsc ? "asc" : "desc"]);
    }

    return filtered;
  }, [flights, direction, terminal, sortKey, sortAsc, showPast]);
};

export const FlightsPage: FunctionComponent = () => {
  const [direction, setDirection] = useState("arrival");
  const isArrival = direction === "arrival";
  const [terminal, setTerminal] = useState("0");
  const [sortKey, setSortKey] = useState<SortKey | null>(null);
  const [sortAsc, setSortAsc] = useState(true);
  const [showPast, setShowPast] = useState(true);

  const { terminals } = useSessionData();
  const { reportId } = useReportContext();
  const { data: flightData, isLoading: flightDataLoading } = getFlightData(
    reportId,
    direction,
  );
  const { mutate: toggleStar } = toggleFlightStar(reportId, direction);
  const isDeparture = direction === "departure";
  const { is_staff } = useSessionData();

  const filteredData: Flight[] = useFilteredSortedFlights(
    flightData,
    direction,
    terminal,
    sortKey,
    sortAsc,
    showPast,
  );

  const { setTitles } = usePage();
  useEffect(() => {
    setTitles(["Flights"]);
  }, []);

  const renderSort = (key: SortKey) => {
    if (key === sortKey) {
      if (sortAsc) {
        return (
          <span>
            <ChevronUpIcon className={"w-4 h-4 opacity-80 mr-2"} />
          </span>
        );
      } else {
        return (
          <span>
            <ChevronDownIcon className={"w-4 h-4 opacity-80 mr-2"} />
          </span>
        );
      }
    }
  };

  const setSort = (key: SortKey) => {
    if (sortKey === key) {
      setSortAsc(!sortAsc);
    } else {
      setSortKey(key);
    }
  };

  function renderTableHeader(
    key: keyof Flight,
    name: string,
    sortable: boolean,
    width: string,
  ) {
    const handleClick = () => {
      if (sortable) {
        setSort(key);
      }
    };

    return (
      <th className={`${width} cursor-pointer text-sm `} onClick={handleClick}>
        <div className={"flex items-center justify-center"}>
          {sortable ? (
            <>
              {renderSort(key)} {name}
            </>
          ) : (
            name
          )}
        </div>
      </th>
    );
  }

  return (
    <div className={"mx-12 mt-8"}>
      <div className="flex items-center">
        <div className={"mr-4"}>
          <Tabs
            value={direction}
            onValueChange={(value) => setDirection(value)}
          >
            <TabsList>
              <TabsTrigger value="departure">Departure</TabsTrigger>
              <TabsTrigger value="arrival">Arrival</TabsTrigger>
            </TabsList>
          </Tabs>
        </div>
        <div className="mr-4">
          <Tabs value={terminal} onValueChange={(value) => setTerminal(value)}>
            <TabsList>
              <TabsTrigger value="0">All Terminals</TabsTrigger>
              {terminals.map((t) => (
                <TabsTrigger value={t.terminal_name} key={t.id}>
                  {t.terminal_name}
                </TabsTrigger>
              ))}
            </TabsList>
          </Tabs>
        </div>
        <div>
          <div className="flex items-center space-x-2">
            <Checkbox
              id="terms"
              checked={showPast}
              onCheckedChange={(val) =>
                val !== "indeterminate" && setShowPast(val)
              }
            />
            <label
              htmlFor="terms"
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              {isArrival ? "Show Landed flights" : "Show Departed Flights"}
            </label>
          </div>
        </div>
      </div>
      {flightDataLoading && (
        <div className={"mt-4 text-sm dark:text-slate-300"}>
          Please wait while we load your flights
        </div>
      )}
      <div>
        {filteredData.length > 0 && (
          <table className={"flight-table"}>
            <thead>
              <tr>
                <th className={"w-10"}></th>
                {renderTableHeader("number", "Number", true, "w-36")}
                {renderTableHeader("dep_iata", "Dep", true, "w-20")}
                {renderTableHeader(
                  "sch_dep_time",
                  "Sch. Dep Time",
                  true,
                  "w-30",
                )}
                {renderTableHeader("dep_time", "Dep Time", true, "w-30")}
                {renderTableHeader("arr_iata", "Arr", true, "w-20")}
                {renderTableHeader(
                  "sch_arr_time",
                  "Sch. Arr Time",
                  true,
                  "w-30",
                )}
                {renderTableHeader("arr_time", "Arr Time", true, "w-30")}
                {renderTableHeader("delay", "Delay", true, "w-30")}
                {renderTableHeader("terminal", "Terminal", true, "w-16")}
                {renderTableHeader("gate", "Gate", true, "w-16")}
                {renderTableHeader(
                  "aircraft_type_iata",
                  "Aircraft",
                  true,
                  "w-32",
                )}
                {is_staff && <th className={"w-24"}>Issues</th>}
                {is_staff && <th className={"w-24"}>Logic</th>}
              </tr>
            </thead>
            <tbody>
              {filteredData.map((row) => {
                return (
                  <tr
                    key={row.id}
                    className={clsx(row.starred && "!bg-amber-900")}
                  >
                    <td
                      className={"cursor-pointer"}
                      onClick={() => toggleStar(row.id)}
                    >
                      {row.starred ? (
                        <StarFilledIcon className={"ml-1 text-amber-200"} />
                      ) : (
                        <StarIcon className={"ml-1"} />
                      )}
                    </td>
                    <td>{row.number}</td>
                    <td>{row.dep_iata}</td>
                    <td>{row.sch_dep_time}</td>
                    <td>{row.dep_time}</td>
                    <td>{row.arr_iata}</td>
                    <td>{row.sch_arr_time}</td>
                    <td>{row.arr_time}</td>
                    <td>{row.delay}</td>
                    <td>{row.terminal}</td>
                    <td>
                      {row.gate_guess_flag ? (
                        <span className="opacity-70">*{row.gate}</span>
                      ) : (
                        <span>{row.gate}</span>
                      )}
                    </td>
                    <td>{row.aircraft_type_iata}</td>
                    {is_staff && (
                      <td className={"!p-0"}>
                        {row.issues.length > 0 && (
                          <Popover>
                            <PopoverTrigger>
                              <ExclamationTriangleIcon
                                className={"mt-2 h-5 w-4 text-orange-700"}
                              />
                            </PopoverTrigger>
                            <PopoverContent>
                              <div className={"prose dark:prose-invert"}>
                                <pre>{row.issues.join("\n")}</pre>
                              </div>{" "}
                            </PopoverContent>
                          </Popover>
                        )}
                      </td>
                    )}
                    {is_staff && <td>{row.gate_assignment_type}</td>}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
      <ScrollToTop smooth className={"!bg-slate-600 pl-1.5"} />
    </div>
  );
};
