import * as React from "react";
import { addDays, format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { DateRange } from "react-day-picker";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { usePage } from "@/components/page/PageContext";
import { useEffect } from "react";
import { FilterBuilder } from "@/components/landing/FeeFilter";
import { getRangeReport, MultiDayAggregateFees } from "@/queries/landing";
import { MultiSeriesChart } from "@/pages/data/DataChart";

export function DatePickerWithRange({
  className,
}: React.HTMLAttributes<HTMLDivElement>) {
  const [date, setDate] = React.useState<DateRange | undefined>({
    from: new Date(2022, 0, 20),
    to: addDays(new Date(2022, 0, 20), 20),
  });

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "w-[300px] justify-start text-left font-normal",
              !date && "text-muted-foreground",
            )}
          >
            <CalendarIcon />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, "LLL dd, y")} -{" "}
                  {format(date.to, "LLL dd, y")}
                </>
              ) : (
                format(date.from, "LLL dd, y")
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={setDate}
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}

function DateRangeReportSummary({ report }: { report: MultiDayAggregateFees }) {
  const formatCurrency = (cents: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(cents / 100);
  };

  const formatDate = (dateStr: string) => {
    return new Date(dateStr).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  // Calculate total flights
  const totalFlights = report.days.reduce(
    (sum, day) => sum + day.flight_count,
    0,
  );

  return (
    <div className="border-gray-600 border bg-slate-900 rounded-lg shadow-lg p-6 w-full text-slate-200">
      <h2 className="text-xl font-semibold mb-4 text-slate-100">
        Landing Fee Report
      </h2>

      {/* Key Metrics */}
      <div className="grid grid-cols-8 gap-4 mb-6">
        <div className="bg-slate-700 p-4 rounded-lg col-span-2">
          <div className="text-sm text-slate-400">Total Revenue</div>
          <div className="text-2xl font-bold text-slate-100">
            {formatCurrency(report.total_fees)}
          </div>
        </div>
        <div className="bg-slate-700 p-4 rounded-lg col-span-2">
          <div className="text-sm text-slate-400">Total Flights</div>
          <div className="text-2xl font-bold text-slate-100">
            {totalFlights}
          </div>
        </div>
        <div className="bg-slate-700 p-4 rounded-lg col-span-4">
          <div className="text-sm text-slate-400">Date Range</div>
          <div className="text-lg font-bold text-slate-100">
            {formatDate(report.start_date)} - {formatDate(report.end_date)}
          </div>
          <div className="text-sm text-slate-400 mt-1">
            ({report.num_days} days)
          </div>
        </div>
      </div>

      {/* Breakdowns */}
      <div className="grid grid-cols-3 gap-6">
        {/* Flight Type Breakdown */}
        <div>
          <h3 className="text-sm font-semibold text-slate-400 mb-2">
            Flight Types
          </h3>
          <div className="space-y-2">
            {report.flight_type_breakdown.map((item) => (
              <div key={item.item} className="flex justify-between">
                <span className="text-sm text-slate-300">{item.item}</span>
                <span className="font-medium text-slate-200">
                  {formatCurrency(item.amount_cents)}
                </span>
              </div>
            ))}
          </div>
        </div>

        {/* Carrier Breakdown - Show only carriers with non-zero amounts */}
        <div>
          <h3 className="text-sm font-semibold text-slate-400 mb-2">
            Top Carriers
          </h3>
          <div className="space-y-2">
            {report.carrier_breakdown
              .filter((carrier) => carrier.amount_cents > 0)
              .sort((a, b) => b.amount_cents - a.amount_cents)
              .slice(0, 5)
              .map((item) => (
                <div key={item.item} className="flex justify-between">
                  <span className="text-sm text-slate-300">{item.item}</span>
                  <span className="font-medium text-slate-200">
                    {formatCurrency(item.amount_cents)}
                  </span>
                </div>
              ))}
          </div>
        </div>

        {/* Fee Tier Breakdown */}
        <div>
          <h3 className="text-sm font-semibold text-slate-400 mb-2">
            Fee Tiers
          </h3>
          <div className="space-y-2">
            {report.fee_tier_breakdown.map((item) => (
              <div key={item.item} className="flex justify-between">
                <span className="text-sm text-slate-300">
                  {item.item
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, (l) => l.toUpperCase())}
                </span>
                <span className="font-medium text-slate-200">
                  {formatCurrency(item.amount_cents)}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function DailyFeeChart({ report }: { report: MultiDayAggregateFees }) {
  // Transform the data for the chart
  const feeTiers = Array.from(
    new Set(report.fee_tier_breakdown.map((tier) => tier.item)),
  );

  // Sort days in ascending order
  const sortedDays = [...report.days].sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  );

  // Create series data for each fee tier
  const series = feeTiers.map((tierName) => {
    return {
      name: tierName
        .replace(/_/g, " ")
        .replace(/\b\w/g, (l) => l.toUpperCase()),
      values: sortedDays.map((day) => {
        // Find the fee amount for this tier on this day
        const tierFee = day.fee_tier_breakdown.find(
          (tier) => tier.item === tierName,
        );
        return tierFee ? tierFee.amount_cents / 100 : 0; // Convert cents to dollars
      }),
    };
  });

  // Create labels from dates
  const labels = sortedDays.map((day) =>
    new Date(day.date).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    }),
  );

  return (
    <div className="border-gray-600 border bg-slate-900 rounded-lg shadow-lg p-6 mt-6 w-full">
      <h2 className="text-xl font-semibold mb-8 text-slate-100">
        Daily Fee Breakdown
      </h2>
      <div className="h-[400px]">
        <MultiSeriesChart
          series={series}
          labels={labels}
          xAxisTitle="Date"
          yAxisTitle="Revenue ($)"
          chartType="column"
          stacked={true}
        />
      </div>
    </div>
  );
}

function DateRangeDailyBreakdown({
  report,
}: {
  report: MultiDayAggregateFees;
}) {
  const formatCurrency = (cents: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(cents / 100);
  };

  const formatDate = (dateStr: string) => {
    return new Date(dateStr).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  // Sort days in ascending order
  const sortedDays = [...report.days].sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  );

  return (
    <div className="border-gray-600 border bg-slate-900 rounded-lg shadow-lg p-6 mt-6 w-full text-slate-200">
      <h2 className="text-xl font-semibold mb-4 text-slate-100">
        Daily Breakdown
      </h2>

      <table className="w-full">
        <thead>
          <tr className="text-sm text-slate-400 border-b border-slate-700">
            <th className="text-left pb-2">Date</th>
            <th className="text-right pb-2">Flights</th>
            <th className="text-right pb-2">Revenue</th>
          </tr>
        </thead>
        <tbody>
          {sortedDays.map((day) => (
            <tr
              key={day.date}
              className="border-b border-slate-700/50 hover:bg-slate-700/30"
            >
              <td className="py-3">
                <a
                  href={`/data/landing/day/${day.date}`}
                  className="text-blue-400 hover:text-blue-300 hover:underline"
                >
                  {formatDate(day.date)}
                </a>
              </td>
              <td className="text-right py-3">{day.flight_count}</td>
              <td className="text-right py-3">
                {formatCurrency(day.total_fees)}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr className="text-sm font-semibold">
            <td className="pt-3">Total</td>
            <td className="text-right pt-3">
              {sortedDays.reduce((sum, day) => sum + day.flight_count, 0)}
            </td>
            <td className="text-right pt-3">
              {formatCurrency(report.total_fees)}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

export function DateRangePage() {
  const { setTitles } = usePage();
  const startDate = "2024-11-15"; // TODO: We will build out a UI for this later
  const endDate = "2024-11-18"; // TODO: hardocde parameter before we build out a UI

  useEffect(() => {
    setTitles(["Landing Fees"]);
  }, []);

  const { data: rangeReport } = getRangeReport({
    startDate: "2024-11-01",
    endDate: "2024-11-17",
    filter: {
      include_diversion: true,
    },
    enabled: true,
  });

  return (
    <div>
      <div className="flex w-full p-4">
        <div>
          <div className="uppercase mb-2 text-slate-400">Date Range:</div>
          <DatePickerWithRange />
          <FilterBuilder />
        </div>
        <div className="ml-4 w-[900px]">
          {rangeReport && (
            <>
              <DateRangeReportSummary report={rangeReport} />
              <DailyFeeChart report={rangeReport} />
              <DateRangeDailyBreakdown report={rangeReport} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
